var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt--2"},[_c('KTPortlet',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.informationData.product_info)?_c('h3',{staticClass:"kt-portlet__head-title",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.informationData.cps_order_number)+" / "+_vm._s(_vm.informationData.product_info.brand)+" / "+_vm._s(_vm.informationData.product_info.brand_article_number)+" / "+_vm._s(_vm.informationData.product_info.product_name_en ? _vm.informationData.product_info.product_name_en : _vm.informationData.product_info.product_name)+" ")]):_vm._e()]},proxy:true},{key:"body",fn:function(){return [_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-tabs',{attrs:{"content-class":"mt-3 mb-7","lazy":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":"Information"}},[_c('Information',{attrs:{"initData":_vm.informationData,"submitError":_vm.submitError}}),_c('SimpleTable',{staticStyle:{"margin-top":"20px"},attrs:{"items":_vm.items,"fields":_vm.fields,"tableId":'purchase-target-overview-list',"initDataFn":_vm.initDataFn}})],1),_c('b-tab',{attrs:{"title":"logs"}},[_c('SimpleTable',{attrs:{"items":_vm.items2,"fields":_vm.fields2,"tableId":'purchase-target'}})],1)],1),(_vm.ifConsole)?_c('div',{staticClass:"console"},[_c('b-overlay',{attrs:{"show":_vm.loading}},[(_vm.informationData.status == 'On Hold')?_c('div',[(_vm.informationData.status == 'On Hold')?_c('b-button',{staticClass:"mr-2 warning",attrs:{"variant":"warning","disabled":_vm.unHoldDisabled},on:{"click":function($event){return _vm.unHoldTarget()}}},[_vm._v("Un Hold "),_c('b-icon',{attrs:{"icon":"play-circle","aria-hidden":"true"}})],1):_vm._e()],1):_c('div',[(
                  _vm.informationData.status == 'New' ||
                  _vm.informationData.status == 'Processing'
                )?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","disabled":_vm.saveDisabled},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save "),_c('i',{staticClass:"far fa-save"})]):_vm._e(),(_vm.informationData.status != 'Voided' && 0)?_c('Plan',{staticClass:"mr-2 button",attrs:{"purchaseTargetId":_vm.informationData.id,"maxQty":_vm.informationData.not_purchased_qty,"disabled":_vm.informationData.not_purchased_qty <= 0,"initFn":_vm.initDataFn}}):_vm._e(),(_vm.informationData.status != 'Voided')?_c('CreatePurchase',{staticClass:"mr-2 button",attrs:{"purchaseTargetId":_vm.informationData.id,"maxQty":_vm.informationData.not_purchased_qty,"disabled":_vm.informationData.not_purchased_qty <= 0,"initFn":_vm.initDataFn,"order-number":_vm.informationData.cps_order_number,"brand":_vm.informationData.product_info.brand}}):_vm._e(),(
                  _vm.informationData.status == 'New' ||
                  _vm.informationData.status == 'Processing'
                )?_c('b-button',{staticClass:"mr-2 warning",attrs:{"variant":"warning","disabled":_vm.onHoldDisabled},on:{"click":function($event){return _vm.onHoldTarget()}}},[_vm._v("On Hold "),_c('b-icon',{attrs:{"icon":"pause-circle","aria-hidden":"true"}})],1):_vm._e(),(
                  _vm.informationData.status != 'Voided' &&
                  _vm.informationData.status != 'Pending'
                )?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"dark","disabled":_vm.voidDisabled},on:{"click":function($event){return _vm.voidTarget()}}},[_vm._v("Void Target "),_c('i',{staticClass:"fas fa-ban"})]):_vm._e()],1),_c('router-link',{staticClass:"btn btn-secondary",attrs:{"to":{
                name: 'purchase-target-list'
              }}},[_vm._v("Back to List "),_c('b-icon',{attrs:{"icon":"reply-fill","aria-hidden":"true"}})],1)],1)],1):_vm._e(),_c('b-modal',{ref:"modal",attrs:{"centered":"","no-close-on-backdrop":"","scrollable":"","title":"On Hold Target","id":"on_hold_target"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
              var close = ref.close;
return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.submitDisabled},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" YES ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return close()}}},[_vm._v(" NO ")])]}}]),model:{value:(_vm.showOnHoldModal),callback:function ($$v) {_vm.showOnHoldModal=$$v},expression:"showOnHoldModal"}},[_c('b-overlay',{attrs:{"show":_vm.modalloading}},[_c('p',{staticClass:"reconfirm mb-2"},[_vm._v(" Are you sure to On Hold this purchase target? ")])])],1),_c('b-modal',{ref:"modal",attrs:{"centered":"","no-close-on-backdrop":"","scrollable":"","title":"UnHold Target","id":"un_hold_target"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
              var close = ref.close;
return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.submitDisabled},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" YES ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return close()}}},[_vm._v(" NO ")])]}}]),model:{value:(_vm.showUnHoldModal),callback:function ($$v) {_vm.showUnHoldModal=$$v},expression:"showUnHoldModal"}},[_c('b-overlay',{attrs:{"show":_vm.modalloading}},[_c('p',{staticClass:"reconfirm mb-2"},[_vm._v(" Are you sure to UnHold this purchase target? ")])])],1),_c('b-modal',{ref:"modal",attrs:{"centered":"","no-close-on-backdrop":"","scrollable":"","title":"Void Target","id":"void_target"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
              var close = ref.close;
return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.submitDisabled},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" YES ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return close()}}},[_vm._v(" NO ")])]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-overlay',{attrs:{"show":_vm.modalloading}},[_c('p',{staticClass:"reconfirm mb-2"},[_vm._v(" Are you sure to void this purchase target? ")]),(0)?_c('div',[_c('b-form-checkbox',{attrs:{"name":"b-form-checkbox","value":"1","unchecked-value":"0"},model:{value:(_vm.cancel_purchase_mark),callback:function ($$v) {_vm.cancel_purchase_mark=$$v},expression:"cancel_purchase_mark"}},[_vm._v("Unmark together in Order system")])],1):_vm._e()])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }