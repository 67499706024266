<template>
  <div class="mt--2">
    <KTPortlet>
      <template v-slot:title>
        <h3
          class="kt-portlet__head-title"
          style="width: 100%"
          v-if="informationData.product_info"
        >
          {{ informationData.cps_order_number }} /
          {{ informationData.product_info.brand }} /
          {{ informationData.product_info.brand_article_number }} /
          {{
            informationData.product_info.product_name_en
              ? informationData.product_info.product_name_en
              : informationData.product_info.product_name
          }}
        </h3>
      </template>
      <template v-slot:body>
        <b-overlay :show="loading">
          <b-tabs content-class="mt-3 mb-7" lazy v-model="tabIndex">
            <b-tab title="Information">
              <Information
                :initData="informationData"
                :submitError="submitError"
              ></Information>
              <SimpleTable
                :items="items"
                :fields="fields"
                :tableId="'purchase-target-overview-list'"
                :initDataFn="initDataFn"
                style="margin-top: 20px"
              ></SimpleTable>
            </b-tab>
            <b-tab title="logs">
              <SimpleTable
                :items="items2"
                :fields="fields2"
                :tableId="'purchase-target'"
              ></SimpleTable>
            </b-tab>
          </b-tabs>
          <div class="console" v-if="ifConsole">
            <b-overlay :show="loading">
              <div v-if="informationData.status == 'On Hold'">
                <b-button
                  variant="warning"
                  class="mr-2 warning"
                  v-if="informationData.status == 'On Hold'"
                  :disabled="unHoldDisabled"
                  @click="unHoldTarget()"
                  >Un Hold
                  <b-icon icon="play-circle" aria-hidden="true"></b-icon>
                </b-button>
              </div>
              <div v-else>
                <b-button
                  v-if="
                    informationData.status == 'New' ||
                    informationData.status == 'Processing'
                  "
                  variant="primary"
                  class="mr-2"
                  :disabled="saveDisabled"
                  @click="save()"
                  >Save
                  <i class="far fa-save"></i>
                  <!-- <b-icon icon="folder-check" aria-hidden="true"></b-icon
              > -->
                </b-button>
                <Plan
                  v-if="informationData.status != 'Voided' && 0"
                  :purchaseTargetId="informationData.id"
                  :maxQty="informationData.not_purchased_qty"
                  :disabled="informationData.not_purchased_qty <= 0"
                  :initFn="initDataFn"
                  class="mr-2 button"
                ></Plan>
                <CreatePurchase
                  v-if="informationData.status != 'Voided'"
                  :purchaseTargetId="informationData.id"
                  :maxQty="informationData.not_purchased_qty"
                  :disabled="informationData.not_purchased_qty <= 0"
                  :initFn="initDataFn"
                  :order-number="informationData.cps_order_number"
                  :brand="informationData.product_info.brand"
                  class="mr-2 button"
                ></CreatePurchase>
                <b-button
                  variant="warning"
                  class="mr-2 warning"
                  v-if="
                    informationData.status == 'New' ||
                    informationData.status == 'Processing'
                  "
                  :disabled="onHoldDisabled"
                  @click="onHoldTarget()"
                  >On Hold
                  <b-icon icon="pause-circle" aria-hidden="true"></b-icon>
                </b-button>
                <b-button
                  variant="dark"
                  class="mr-2"
                  v-if="
                    informationData.status != 'Voided' &&
                    informationData.status != 'Pending'
                  "
                  :disabled="voidDisabled"
                  @click="voidTarget()"
                  >Void Target
                  <i class="fas fa-ban"></i>
                  <!-- <b-icon icon="slash-circle" aria-hidden="true"></b-icon
              > -->
                </b-button>
              </div>
              <router-link
                :to="{
                  name: 'purchase-target-list'
                }"
                class="btn btn-secondary"
                >Back to List
                <b-icon icon="reply-fill" aria-hidden="true"></b-icon
              ></router-link>
            </b-overlay>
          </div>
          <b-modal
            v-model="showOnHoldModal"
            centered
            no-close-on-backdrop
            scrollable
            title="On Hold Target"
            id="on_hold_target"
            ref="modal"
          >
            <b-overlay :show="modalloading">
              <p class="reconfirm mb-2">
                Are you sure to On Hold this purchase target?
              </p>
            </b-overlay>
            <template v-slot:modal-footer="{ close }">
              <b-button
                variant="primary"
                @click="submit()"
                :disabled="submitDisabled"
              >
                YES
              </b-button>
              <b-button variant="secondary" @click="close()"> NO </b-button>
            </template></b-modal
          >
          <b-modal
            v-model="showUnHoldModal"
            centered
            no-close-on-backdrop
            scrollable
            title="UnHold Target"
            id="un_hold_target"
            ref="modal"
          >
            <b-overlay :show="modalloading">
              <p class="reconfirm mb-2">
                Are you sure to UnHold this purchase target?
              </p>
            </b-overlay>
            <template v-slot:modal-footer="{ close }">
              <b-button
                variant="primary"
                @click="submit()"
                :disabled="submitDisabled"
              >
                YES
              </b-button>
              <b-button variant="secondary" @click="close()"> NO </b-button>
            </template></b-modal
          >
          <b-modal
            v-model="showModal"
            centered
            no-close-on-backdrop
            scrollable
            title="Void Target"
            id="void_target"
            ref="modal"
          >
            <b-overlay :show="modalloading">
              <p class="reconfirm mb-2">
                Are you sure to void this purchase target?
              </p>
              <div v-if="0">
                <b-form-checkbox
                  v-model="cancel_purchase_mark"
                  name="b-form-checkbox"
                  value="1"
                  unchecked-value="0"
                  >Unmark together in Order system</b-form-checkbox
                >
              </div>
            </b-overlay>
            <template v-slot:modal-footer="{ close }">
              <b-button
                variant="primary"
                @click="submit()"
                :disabled="submitDisabled"
              >
                YES
              </b-button>
              <b-button variant="secondary" @click="close()"> NO </b-button>
            </template></b-modal
          >
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from '@/views/content/Portlet';
import Information from '@/views/content/Detail/PurchaseTarget/Information/Index.vue';
import apiBus from '@/common/apiBus/index';
import SimpleTable from '@/components/Table/Simple';
import CreatePurchase from '@/views/content/CreatePurchase/Index.vue';
import Plan from '@/views/content/Plan/Index.vue';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
// import Link from '@/views/content/NewAdd/Information/Link';
export default {
  name: 'PurchaseTargetDetail',
  components: { KTPortlet, Information, SimpleTable, CreatePurchase, Plan },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      informationData: {},
      showModal: false,
      showOnHoldModal: false,
      showUnHoldModal: false,
      modalloading: false,
      submitDisabled: false,
      submitType: '',
      saveDisabled: false,
      ifConsole: false,
      voidDisabled: true,
      onHoldDisabled: true,
      unHoldDisabled: true,
      items: [],
      qtyMax: 0,
      submitError: false,
      cancel_purchase_mark: 0,
      fields: [
        {
          key: 'purchase_id',
          label: 'Purchase ID'
        },
        {
          key: 'purchase_date',
          label: 'Purchase Date'
        },
        {
          key: 'supplier_name',
          label: 'Supplier Name'
        },
        {
          key: 'reserve_no',
          label: 'Reserve No.'
        },
        {
          key: 'qty',
          label: 'Quantity'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'action',
          label: ''
        }
      ],
      items2: [],
      fields2: [
        {
          key: 'time',
          label: 'Time'
        },
        {
          key: 'system_user',
          label: 'System User'
        },
        {
          key: 'action_text',
          label: 'Action'
        },
        {
          key: 'target_amount',
          label: 'Target Amount'
        },
        {
          key: 'to_be_purchased',
          label: 'To be purchased'
        },
        {
          key: 'reserved_qty',
          label: 'Reserved Amount'
        },
        {
          key: 'confirmed_qty',
          label: 'Confirmed Amount'
        },
        {
          key: 'delivered_qty',
          label: 'Delivered Amount'
        },
        {
          key: 'stored_qty',
          label: 'Stored Amount'
        },
        {
          key: 'details',
          label: 'Details'
        }
      ],
      save_data: [],
      options: {}
    };
  },

  methods: {
    initDataFn(index) {
      if (index) this.logsList();
      this.loading = true;
      this.voidDisabled = false;
      this.onHoldDisabled = false;
      this.unHoldDisabled = false;
      this.submitError = false;
      apiBus.purchase
        .purchaseTargetDetail({
          purchase_target_id: this.id
        })
        .then((data) => {
          this.ifConsole ? '' : (this.ifConsole = true);
          this.loading = false;
          this.informationData = data.data.data;
          this.qtyMax = data.data.data.qty;
          this.informationData.order_priority =
            this.informationData.order_priority;
          data.data.data.purchase_list.forEach((key) => {
            key.action = 'PurchaseTargetDetail';
          });
          this.items = data.data.data.purchase_list;
          if (this.items.length > 0) {
            this.items.forEach((item) => {
              if (item.status != 'Voided' && item.status != 'Failed') {
                this.voidDisabled = true;
                this.onHoldDisabled = true;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    onHoldTarget() {
      this.showOnHoldModal = true;
      this.submitDisabled = false;
      this.submitType = '1';
    },
    unHoldTarget() {
      this.showUnHoldModal = true;
      this.submitDisabled = false;
      this.submitType = '2';
    },
    voidTarget() {
      this.showModal = true;
      this.submitDisabled = false;
      this.submitType = '3';
    },
    submit() {
      this.modalloading = true;
      this.submitDisabled = true;
      if (this.submitType == '3') {
        apiBus.purchase
          .voidedPurchaseTarget({
            purchase_target_id: this.id,
            cancel_purchase_mark: this.cancel_purchase_mark
          })
          .then((data) => {
            console.log(data);
            this.modalloading = false;
            this.submitDisabled = false;
            this.showModal = false;
            this.initDataFn(1);
          })
          .catch((error) => {
            console.log(error);
            this.modalloading = false;
            this.submitDisabled = false;
            this.$store.dispatch(PROMPT_STATUS, {
              dismissCountDown: 9999999999, //初始提示作用于定时器
              alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
              alertText:
                error.data == undefined ? error.message : error.data.message // 提示内容
            });
          });
      } else if (this.submitType == '2') {
        apiBus.purchase
          .unHoldPurchaseTarget({
            purchase_target_id: this.id
          })
          .then((data) => {
            console.log(data);
            this.modalloading = false;
            this.submitDisabled = false;
            this.showUnHoldModal = false;
            this.initDataFn(1);
          })
          .catch((error) => {
            console.log(error);
            this.modalloading = false;
            this.submitDisabled = false;
            this.$store.dispatch(PROMPT_STATUS, {
              dismissCountDown: 9999999999, //初始提示作用于定时器
              alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
              alertText:
                error.data == undefined ? error.message : error.data.message // 提示内容
            });
          });
      } else {
        apiBus.purchase
          .onHoldPurchaseTarget({
            purchase_target_id: this.id
          })
          .then((data) => {
            console.log(data);
            this.modalloading = false;
            this.submitDisabled = false;
            this.showOnHoldModal = false;
            this.initDataFn(1);
          })
          .catch((error) => {
            console.log(error);
            this.modalloading = false;
            this.submitDisabled = false;
            this.$store.dispatch(PROMPT_STATUS, {
              dismissCountDown: 9999999999, //初始提示作用于定时器
              alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
              alertText:
                error.data == undefined ? error.message : error.data.message // 提示内容
            });
          });
      }

      // this.informationData.status = 'Voided';
    },
    ifAllCheckbox() {
      if (this.save_data.length == this.items.length) {
        this.fields[0].allSelect = true;
      } else {
        this.fields[0].allSelect = false;
      }
    },
    checkboxChange(select, index) {
      var splice_index = this.save_data.indexOf(index);
      if (select) {
        this.save_data.push(index);
      } else {
        this.save_data.splice(splice_index, 1);
      }
      this.ifAllCheckbox();
    },
    checkboxAll(select) {
      if (select) {
        this.items.forEach((item) => {
          item.select = true;
        });
      } else {
        this.items.forEach((item) => {
          item.select = false;
        });
      }
    },
    save() {
      // if (this.informationData.qty > this.qtyMax) {
      //   this.$store.dispatch(PROMPT_STATUS, {
      //     dismissCountDown: 5, //初始提示作用于定时器
      //     alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
      //     alertText:
      //       'Target Quantity Cannot be greater than the original value.'
      //   });
      //   this.submitError = true;
      //   return false;
      // }

      if (this.informationData.to_warehouse_deadline == null) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 9999999999, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText:
            'Target Quantity Cannot be greater than the original value.'
        });
        this.submitError = true;
        return false;
      }
      this.loading = true;
      this.saveDisabled = true;
      // console.log(this.informationData);
      apiBus.purchase
        .purchaseTargetSave({
          purchase_target_id: parseInt(this.id),
          product_id: this.informationData.product_id,
          color_name: this.informationData.product_info.color_name,
          size: this.informationData.product_info.size,
          qty: this.informationData.qty,
          order_priority: this.informationData.order_priority,
          to_warehouse_deadline: this.informationData.to_warehouse_deadline
        })
        .then((data) => {
          console.log(data);
          this.loading = false;
          this.saveDisabled = false;
          this.initDataFn(1);
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch((error) => {
          this.loading = false;
          this.saveDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 9999999999, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    logsList() {
      apiBus.purchase
        .purchaseTargetLogs({
          purchase_target_id: this.id
        })
        .then((data) => {
          this.items2 = data.data.data.list;
          Object.keys(this.items2).forEach((key) => {
            this.items2[key].action_text = this.items2[key].action;
          });
          // console.log(this.item2);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    init() {
      let { purchaseId } = this.$route.params;
      this.id = purchaseId;
      this.initDataFn();
      this.logsList();
    }
  },

  computed: {},
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.btn-warning.warning {
  background: rgba(232, 126, 4, 0.9);
  border-color: rgba(232, 126, 4, 0.9);
}
.btn-warning.warning:hover {
  background: rgba(232, 126, 4, 1);
  border-color: rgba(232, 126, 4, 1);
}
</style>
